import './sport_wellness.scss';

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import Modi from '../src/components/Modi/Modi';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import SEO from '../src/helpers/seo';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import Tyr from '../src/components/Tyr/Tyr';
import Ymir from '../src/components/Ymir/Ymir';
import Hod from '../src/components/Hod/Hod';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query sportWellness($locale: String!) {
    allSportDetailsHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allSportSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allSportDetailsImageHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allSportTextBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          description
        }
      }
    }
    allSportDiscoverParksBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          text
          title_url
          localImage {
            childImageSharp {
              fluid(maxWidth: 600, maxHeight: 600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          image
          button1 {
            cta
            ctaText
            size
            color
          }
          button2 {
            cta
            ctaText
            size
            color
          }
        }
      }
    }
    allSportFreyaBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          buttons {
            cta
            size
            color
            ctaText
          }
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allDistSportWellnessBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allSportRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class SportWellness extends Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allSportDetailsHeaderBlock.edges[0].node.title,
      image: {
        url: this.props.data.allSportDetailsImageHeaderBlock.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    const modiData = {
      Image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/047/original/PortAventura_Hotels_Brand_Master.png',
      Text: this.props.data.allSportDetailsHeaderBlock.edges[0].node.description,
      classContainer: 'image',
      button1: {
        cta: buildFunnelUrl(this.props.pageContext.locale, 'hotels'),
        size: 'alone',
        color: 'orange',
        ctaText: tt('RESERVAR AHORA', this.props.pageContext.locale),
      },
    };
    const ymirData = {
      cards: [
        {
          title: tt('HORARIOS Y CALENDARIO', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/horarios-calendario', this.props.pageContext.locale),
          icon: 'calendar',
        },
        {
          title: tt('CÓMO LLEGAR AL RESORT', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/destino/planificar/como-llegar-a-portaventura', this.props.pageContext.locale),
          icon: 'LOCATION',
        },
        {
          title: tt('DESCUENTOS EN ENTRADAS ONLINE', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/promociones', this.props.pageContext.locale),
          icon: 'Methodofpayment',
        },
      ],
    };

    const prepareForTyr = (src) => ({
      cards: src.edges.map((e) => ({
        Title: e.node.title,
        MainImage: e.node.localImage.childImageSharp.fluid,
        altImage: e.node.image_alt,
        Image: e.node.image,
        TitleURL: e.node.title_url,
        Text: e.node.text,
        button1: e.node.button1,
        button2: e.node.button2,
      })),
    });

    const freyaData = {
      cards: this.props.data.allSportFreyaBlock.edges.map((item) => ({
        image: item.node.localImage.childImageSharp.fluid,
        altImage: item.node.image_alt,
        title: item.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: item.node.buttons.cta,
          size: item.node.buttons.size,
          color: item.node.buttons.color,
          ctaText: item.node.buttons.ctaText,
        },
      })),
    };
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allSportSeoData.edges[0].node._0.title}
          description={this.props.data.allSportSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allSportRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allSportDetailsImageHeaderBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="sport-wellness-container">
              <Modi data={modiData} />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allDistSportWellnessBreadCrumbBlock.edges[0].node.name}
              />
              <BlueArrow />
              <div className="text-container">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.allSportTextBlock.edges[0].node.description,
                  }}
                />
              </div>
              <Tyr data={prepareForTyr(this.props.data.allSportDiscoverParksBlock)} />
              <Ymir data={ymirData} />
              <div className="shops-content-promo">
                <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
SportWellness.propTypes = {
  sportWellnessData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default SportWellness;
